import { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import Topbar from "./tabs/global/Topbar";
import Sidebar from "./tabs/global/Sidebar";
import Dashboard from "./tabs/dashboard";
import ActiveSubscriptions from "./tabs/ActiveSubscriptions";
import Users from "./tabs/Users";
import InactiveSubscriptions from "./tabs/InactiveSubscriptions";
import Login from "./components/auth/Login/Login";

import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./tabs/calendar/calendar";
import ProtectedRoute from "./components/ProtectedRoutes";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();

  const isLoginPage = location.pathname === "/";

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {!isLoginPage && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {!isLoginPage && <Topbar setIsSidebar={setIsSidebar} />}
            <Routes>
              <Route path="/" element={<Login />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/active-subscriptions" element={<ActiveSubscriptions />} />
                <Route path="/inactive-subscriptions" element={<InactiveSubscriptions />} />
                <Route path="/users" element={<Users />} />
              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
