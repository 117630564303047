import { useState, useEffect } from "react";
import axios from "axios";
import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";

const InactiveSubscriptions = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      field: "user",
      headerName: "User Name",
      flex: 1,
    },
    {
      field: "userEmail",
      headerName: "User Email",
      flex: 1,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      flex: 1,
    },
    {
      field: "end_at",
      headerName: "End At",
      flex: 1,
    },
    // {
    //   field: "cancel_at",
    //   headerName: "Canceled At",
    //   flex: 1,
    // },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
  ];

  const fetchData = async (page, pageSize) => {
    setLoading(true);
    const token = localStorage.getItem("authToken");

    // debugger;
    try {
      const response = await axios.get(
        `https://backend.iotdefender.io/api/admin/inActiveSubscriptions?page=${
          page + 1
        }&pageSize=${pageSize}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const formatDate = (timestamp) => {
        // Convert timestamp from seconds to milliseconds
        const date = new Date(timestamp * 1000);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      };

      const formattedData = response.data.subscriptions.map((item) => ({
        id: item.id,
        user: item.userName,
        userEmail: item.email,
        start_date: formatDate(item.start_date),
        end_at: formatDate(item.ended_at),
        cancel_at: item.cancel_at,
        status: item.status,
      }));

      setRows(formattedData);
      setRowCount(response.data.totalCount);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page, pageSize);
  }, [page, pageSize]);

  return (
    <Box m="20px">
      <Header
        title="Inactive Subscriptions"
        subtitle="List of active subscriptions for future reference"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          // checkboxSelection
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          paginationMode="server"
          rowCount={rowCount}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 30, 40]}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pagination
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          loading={loading}
          disableSelectionOnClick
          getRowId={(row) => row.id}
        />
      </Box>
    </Box>
  );
};

export default InactiveSubscriptions;
